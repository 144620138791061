<template>
    <!-- 机器人参数字段格式化详情展示 -->
    <span>{{paramFormat(item)}}</span>
</template>
<script>
    import { mapGetters } from 'vuex'
    import Decimal from 'decimal.js'
    import timeFormat from '@/utils/methApi/timeFormat';
    export default {
        name: 'LightvueRobotParamFormat',
        props: ['item'],
        computed: {
            ...mapGetters(['solWebList']),
        },
        methods: {
            // 参数设置字段格式化
            paramFormat(item) {
                // console.log(item)
                var dataArr = item.param?.split(' ');
                if (item.type == 'buy_sell') {
                    // 高抛低吸机器人
                    var yenUnit1 = item.currency.split('_')[0]
                    return this.$t('robot.higherthan') + dataArr[0] + this.$t('robot.sellout') + ', ' +
                        this.$t('robot.below') + dataArr[1] + this.$t('robot.purchase') + ', ' +
                        this.$t('robot.interval') + dataArr[2] + this.$t('robot.second') + ', ' +
                        this.$t('robot.per') + dataArr[3] + yenUnit1 +
                        (dataArr[4] == 1 ? ', ' + this.$t('robot.opponents') : '') + ', ' +
                        this.$t('robot.maximum') + dataArr[5] + yenUnit1 + ', ' +
                        this.$t('robot.minimum') + dataArr[6] + yenUnit1 +
                        (dataArr[7] == 1 ? ', ' + this.$t('robot.working') + this.$t('robot.chuck') : '') +
                        (dataArr[8] == 1 ? ', ' + this.$t('robot.limitOrder') : '') +
                        (dataArr[9] == 1 ? ', ' + this.$t('robot.tradeSelfOrder') : '')
                } else if (item.type == 'iceberg') {
                    // 分批卖出机器人
                    var yenUnit1 = item.currency.split('_')[0]
                    var yenUnit2 = item.currency.split('_')[1]
                    return this.$t('robot.floorPrice') + dataArr[0] + yenUnit2 + ', ' +
                        this.$t('robot.interval2') + dataArr[1] + this.$t('robot.second') + ', ' +
                        this.$t('robot.quantityPerTime') + dataArr[2] + yenUnit1 + ', ' +
                        this.$t('robot.frequency') + dataArr[3]
                } else if (item.type == 'banzhuan') {
                    // 跨所对冲机器人-搬砖
                    var targetPairArr = dataArr[1].split('_')
                    return this.$t('robot.targetExchange') + dataArr[0] + ', ' +
                        this.$t('robot.targetPair') + targetPairArr[0] + '/' + targetPairArr[1] + ', ' +
                        this.$t('robot.currentCharge') + dataArr[2] + '%, ' +
                        this.$t('robot.targetCharge') + dataArr[3] + '%, ' +
                        this.$t('robot.priceDecimal') + dataArr[4] + ', ' +
                        this.$t('robot.quantityDecimal') + dataArr[5]
                } else if (item.type == 'wangge') {
                    // 马丁策略量化机器人
                    var yenUnit2 = item.currency.split('_')[1]
                    return this.$t('robot.openingAmount') + dataArr[0] + yenUnit2 + ', ' +
                        this.$t('robot.repTimes') + dataArr[1] + this.$t('robot.ci') + ', ' +
                        this.$t('robot.interferenceRange') + dataArr[2] + '%, ' +
                        this.$t('robot.stopBackRatio') + dataArr[3] + '%, ' +
                        this.$t('robot.repDifferences') + dataArr[4] + '%, ' +
                        this.$t('robot.addBackRatio') + dataArr[5] + '%, ' +
                        (dataArr[6] == 1 ? this.$t('robot.firstDouble') + ', ' : '') +
                        this.$t('robot.addMultiple') + dataArr[7] + this.$t('robot.times')
                }
                // else if(item.type=='grid'){
                // 	// 网格策略量化机器人-旧
                // 	return this.$t('robot.priceRange')+dataArr[0]+'~'+dataArr[1]+', '+this.$t('robot.grids')+dataArr[2]+', '+this.$t('robot.perGear')+dataArr[3]+', '+this.$t('robot.maintain')+dataArr[4]+', '+this.$t('robot.stopPrice')+dataArr[5]
                // }
                else if (item.type == 'grid') {
                    // else if(item.type=='wave'){
                    // 网格策略量化机器人
                    var yenUnit2 = item.currency.split('_')[1]
                    return this.$t('robot.firstAmount') + dataArr[0] + yenUnit2 + ', ' +
                        this.$t('robot.grids') + dataArr[1] + ', ' +
                        this.$t('robot.stopRatio') + dataArr[2] + '%, ' +
                        this.$t('robot.addInterval') + dataArr[3] + '%, ' +
                        this.$t('robot.addIntervalIncrease') + dataArr[4] + this.$t('robot.times') + ', ' +
                        this.$t('robot.addAmountIncrease') + dataArr[5] + this.$t('robot.times')
                }
                else if (item.type == 'shua') {
                    // 成交量对敲机器人
                    var yenUnit1 = item.currency.split('_')[0]
                    return this.$t('robot.counterQuantity') + dataArr[0] + '~' + dataArr[1] + yenUnit1 + ', ' +
                        this.$t('robot.perMinute') + dataArr[2] + '~' + dataArr[3] + this.$t('robot.ci') + ', ' +
                        this.$t('robot.priceDecimal') + dataArr[4] + ', ' +
                        this.$t('robot.numTick') + dataArr[5] +
                        (dataArr[6] == 1 ? ', ' + this.$t('robot.clearOrder') : '') +
                        (dataArr[7] == 1 ? ', ' + this.$t('robot.advancedProtection') : '') +
                        (dataArr[8] == 1 ? ', ' + this.$t('robot.sellOrderFirst') : '')
                } else if (item.type == 'budan_new') {
                    // 盘口管理机器人
                    var yenUnit1 = item.currency.split('_')[0]
                    return this.$t('robot.orderPerNum') + dataArr[0] + yenUnit1 + ', ' +
                        this.$t('robot.sellPerNum') + dataArr[1] + yenUnit1 + ', ' +
                        this.$t('robot.difference') + dataArr[2] + '%, ' +
                        this.$t('robot.perDifference') + dataArr[3] + '%, ' +
                        this.$t('robot.priceDecimal') + dataArr[4] + ', ' +
                        this.$t('robot.maintenance') + dataArr[5] + ', ' +
                        this.$t('robot.numTick') + dataArr[6]
                } else if (item.type == 'hupan') {
                    // 护盘机器人 买盘、卖盘
                    var yenUnit1 = item.currency.split('_')[0]
                    let priceRange
                    if (item.sub_type == 'buy') {
                        priceRange = this.$t('robot.buyPriceRange')
                    } else {
                        priceRange = this.$t('robot.sellPriceRange')
                    }
                    return priceRange + Number(new Decimal(dataArr[0]).mul(new Decimal(100))) + '~' + Number(new Decimal(dataArr[1]).mul(new Decimal(100))) + '%, ' +
                        this.$t('robot.protective') + dataArr[2] + yenUnit1 + ', ' +
                        this.$t('robot.gear') + dataArr[3] + ', ' +
                        this.$t('robot.priceTick') + dataArr[4] + ', ' +
                        this.$t('robot.numTick') + dataArr[5]
                } else if (item.type == 'push') {
                    // 趋势推动机器人
                    var yenUnit = item.sub_type == 'bids' ? item.currency.split('_')[1] : item.currency.split('_')[0]
                    // return this.$t('robot.plan')+dataArr[0]+', '+this.$t('robot.intervalRange')+dataArr[1]+'~'+dataArr[2]+this.$t('robot.random')+', '+this.$t('robot.perInterval')+dataArr[3]+', '+this.$t('robot.startPrice')+dataArr[4]+', '+this.$t('robot.targetPrice')+dataArr[5]
                    return (item.sub_type == 'bids' ? this.$t('robot.buyBtn') : this.$t('robot.sellBtn')) + ', ' +
                        this.$t('robot.plan') + dataArr[0] + ' ' + yenUnit + ', ' +
                        this.$t('robot.singleBudget') + dataArr[1] + ' ' + yenUnit + ', ' +
                        this.$t('robot.endTime') + timeFormat(Number(dataArr[2]), 'dateTime') + ', ' +
                        // this.$t('robot.startPrice') + dataArr[2] + ', ' +
                        this.$t('robot.targetPrice') + dataArr[3] + ', ' +
                        this.$t('robot.priceDecimal') + dataArr[4]
                } else if (item.type == 'hugevol') {
                    // 成交放量机器人
                    return this.$t('robot.tradingVolume') + dataArr[0] + item.currency.split('_')[0] + '/' + this.$t('robot.minute') + ', ' +
                        this.$t('robot.workingHours') + dataArr[1] + this.$t('robot.minutes') + ', ' +
                        this.$t('robot.splitBudget') + dataArr[2] + item.currency.split('_')[1] + ', ' +
                        this.$t('robot.priceDecimal') + dataArr[3]
                } else if (item.type == 'defence') {
                    // 防御机器人
                    var yenUnit1 = item.currency.split('_')[0]
                    var yenUnit2 = item.currency.split('_')[1]
                    return this.$t('robot.orderMin') + dataArr[0] + yenUnit1 + ', ' +
                        this.$t('robot.kaNum') + dataArr[1] + yenUnit1 + ', ' +
                        this.$t('robot.eatNum') + dataArr[2] + yenUnit1 + ', ' +
                        this.$t('robot.maxNum') + dataArr[3] + yenUnit2 + ', ' +
                        this.$t('robot.minNum') + dataArr[4] + yenUnit2 + ', ' +
                        this.$t('robot.priceTick') + dataArr[5] + ', ' +
                        this.$t('robot.numTick') + dataArr[6]
                }

                else if (item.type == 'draw') {
                    // 画线机器人
                    var yenUnit1 = item.currency.split('_')[0]
                    return this.$t('robot.upperLimit') + dataArr[0] + ', ' +
                        this.$t('robot.lowerLimit') + dataArr[1] + ', ' +
                        this.$t('robot.difference') + dataArr[2] + '%, ' +
                        this.$t('robot.perDifference') + dataArr[3] + '%, ' +
                        this.$t('robot.orderPerNum') + dataArr[4] + yenUnit1 + ', ' +
                        this.$t('robot.sellPerNum') + dataArr[5] + yenUnit1 + ', ' +
                        this.$t('robot.singleMax') + dataArr[6] + yenUnit1 + ', ' +
                        this.$t('robot.priceDecimal') + dataArr[7] + ', ' +
                        this.$t('robot.swiping') + dataArr[8] + yenUnit1 + ', ' +
                        this.$t('robot.direction') + (dataArr[9] == 0 ? this.$t('robot.normalMode') : dataArr[9] == 1 ? this.$t('robot.indexFollow') : dataArr[9] == 10 ? this.$t('robot.customMode') : dataArr[9]) +
                        (dataArr[11] ? ', ' + this.$t('robot.quantityDecimal') + dataArr[11] : '')
                } else if (item.type == 'real_genpan') {
                    // 跟盘机器人
                    var yenUnit1 = item.currency.split('_')[0]
                    if (dataArr.length == 8) {
                        return (item.sub_type == 'rebalance' ? this.$t('robot.rebalance') : this.$t('robot.standard')) + ', ' +
                            this.$t('robot.targetDisk') + dataArr[0] + ', ' +
                            this.$t('robot.difference') + dataArr[1] + '%, ' +
                            this.$t('robot.perDifference') + dataArr[2] + '%, ' +
                            this.$t('robot.orderPerNum') + dataArr[3] + yenUnit1 + ', ' +
                            this.$t('robot.sellPerNum') + dataArr[4] + yenUnit1 + ', ' +
                            this.$t('robot.singleMax') + dataArr[5] + yenUnit1 + ', ' +
                            this.$t('robot.priceDecimal') + dataArr[6] + ', ' +
                            this.$t('robot.swiping') + dataArr[7] + yenUnit1
                    } else {
                        var targetPairArr = dataArr[1].split('_')
                        return (item.sub_type == 'rebalance' ? this.$t('robot.rebalance') : this.$t('robot.standard')) + ', ' +
                            this.$t('robot.targetDisk') + dataArr[0] + ', ' +
                            this.$t('robot.targetPair') + targetPairArr[0] + '/' + targetPairArr[1] + ', ' +
                            this.$t('robot.scaling2') + Number(new Decimal(dataArr[2]).mul(new Decimal(100))) + '%, ' +
                            this.$t('robot.difference') + dataArr[3] + '%, ' +
                            this.$t('robot.perDifference') + dataArr[4] + '%, ' +
                            this.$t('robot.orderPerNum') + dataArr[5] + yenUnit1 + ', ' +
                            this.$t('robot.sellPerNum') + dataArr[6] + yenUnit1 + ', ' +
                            this.$t('robot.singleMax') + dataArr[7] + yenUnit1 + ', ' +
                            this.$t('robot.priceDecimal') + dataArr[8] + ', ' +
                            this.$t('robot.swiping') + dataArr[9] + yenUnit1 +
                            (dataArr[10] ? ', ' + this.$t('robot.quantityDecimal') + dataArr[10] : '')
                    }
                } else if (item.type == 'kongpan') {
                    // 高级盘口管理机器人（控盘）
                    var yenUnit1 = item.currency.split('_')[0]
                    // item.currency.split('_')[0] + this.$t('robot.budget') + dataArr[0] + ', ' +
                    // item.currency.split('_')[1] + this.$t('robot.budget') + dataArr[1] + ', ' +
                    return this.$t('robot.benchmarkPrice') + dataArr[2] + ', ' +
                        this.$t('robot.difference') + dataArr[3] + '%, ' +
                        this.$t('robot.paySmall') + dataArr[4] + yenUnit1 + ', ' +
                        this.$t('robot.sellSmall') + dataArr[5] + yenUnit1 + ', ' +
                        this.$t('robot.perDifference') + dataArr[6] + '%, ' +
                        this.$t('robot.maintenance') + dataArr[7] + ', ' +
                        this.$t('robot.payMedium') + dataArr[8] + yenUnit1 + ', ' +
                        this.$t('robot.sellMedium') + dataArr[9] + yenUnit1 + ', ' +
                        this.$t('robot.perDifference') + dataArr[10] + '%, ' +
                        this.$t('robot.maintenance') + dataArr[11] + ', ' +
                        this.$t('robot.payLarge') + dataArr[12] + yenUnit1 + ', ' +
                        this.$t('robot.sellLarge') + dataArr[13] + yenUnit1 + ', ' +
                        this.$t('robot.perDifference') + dataArr[14] + '%, ' +
                        this.$t('robot.maintenance') + dataArr[15] + ', ' +
                        this.$t('robot.priceDecimal') + dataArr[16] +
                        (dataArr[17] ? (', ' + this.$t('robot.quantityDecimal') + dataArr[17]) : '')//新增兼容参数
                } else if (item.type == 'real_genpan_quick') {
                    // 主流币跟盘机器人
                    var yenUnit1 = item.currency.split('_')[0]
                    return this.$t('robot.targetDisk') + dataArr[0] + ', ' +
                        this.$t('robot.difference') + dataArr[1] + '%, ' +
                        this.$t('robot.perDifference') + dataArr[2] + '%, ' +
                        this.$t('robot.buy') + dataArr[3] + yenUnit1 + ', ' +
                        this.$t('robot.offers') + dataArr[4] + yenUnit1 + ', ' +
                        this.$t('robot.priceDecimal') + dataArr[5] + ', ' +
                        this.$t('robot.swiping') + dataArr[6] + yenUnit1 + ', ' +
                        this.$t('robot.numTick') + dataArr[7]
                }
                else if (item.type == 'open_cex') {
                    // 开盘机器人
                    var yenUnit1 = item.currency.split('_')[0]
                    var yenUnit2 = item.currency.split('_')[1]
                    return this.$t('robot.openingTime') + timeFormat(Number(new Decimal(dataArr[0]).mul(new Decimal(1000))), 'dateTime') + ', ' +
                        this.$t('robot.openPrice') + dataArr[1] + yenUnit1 + ', ' +
                        this.$t('robot.topPrice') + dataArr[2] + yenUnit1 + ', ' +
                        this.$t('robot.downPrice') + dataArr[3] + yenUnit1 + ', ' +
                        this.$t('robot.pullOrdersUpAndDown') + dataArr[4] + yenUnit2 + ', ' +
                        this.$t('robot.priceDecimal') + dataArr[5]
                }
                else if (item.type == 'shua_dex') {
                    // Dex画线机器人
                    return this.$t('robot.priceRange') + dataArr[0] + '~' + dataArr[1] + item.currency.split('_')[1] + ', ' +
                        this.$t('robot.transaction') + dataArr[2] + '~' + dataArr[3] + item.currency.split('_')[0] + ', ' +
                        this.$t('robot.intervalTime') + dataArr[4] + this.$t('robot.second') + ', ' +
                        this.$t('robot.operationMode') + (dataArr[5] == 0 ? this.$t('robot.normalMode') : dataArr[5] == 1 ? this.$t('robot.batchOrderMode') : dataArr[5] == 2 ? this.$t('robot.unlimitedWalletMode') : dataArr[5]) + ', ' +
                        this.$t('robot.direction') + (dataArr[6] == 0 ? this.$t('robot.oscillation') : dataArr[6] == 1 ? this.$t('robot.rise') : dataArr[6] == 2 ? this.$t('robot.fall') : dataArr[6] == 10 ? this.$t('robot.customMode') : dataArr[6]) +
                        (this.solWebList.indexOf(item.web) == -1 ? '' : ', ' + this.$t('robot.gasMultiplier') + dataArr[8] + this.$t('robot.times'))
                } else if (item.type == 'shua_dex2') {
                    // Dex刷量机器人
                    return this.$t('robot.priceRange') + dataArr[0] + '~' + dataArr[1] + item.currency.split('_')[1] + ', ' +
                        this.$t('robot.transaction') + dataArr[2] + '~' + dataArr[3] + item.currency.split('_')[0] + ', ' +
                        this.$t('robot.intervalTime') + dataArr[4] + this.$t('robot.second') + ', ' +
                        this.$t('robot.operationMode') + (dataArr[5] == 0 ? this.$t('robot.normalMode') : dataArr[5] == 1 ? this.$t('robot.batchOrderMode') : dataArr[5] == 2 ? this.$t('robot.unlimitedWalletMode') : dataArr[5] == 3 ? this.$t('robot.newWalletMode') : dataArr[5]) +
                        (this.solWebList.indexOf(item.web) == -1 ? '' : ', ' + this.$t('robot.gasMultiplier') + dataArr[6] + this.$t('robot.times'))
                } else if (item.type == 'real_genpan_dex') {
                    // Dex跟盘机器人
                    var yenUnit1 = item.currency.split('_')[0]
                    return this.$t('robot.targetDisk') + dataArr[0] + ', ' +
                        this.$t('robot.targetPair') + dataArr[1] + '/' + dataArr[2] + ', ' +
                        this.$t('robot.scaling') + dataArr[3] + '%, ' +
                        this.$t('robot.intervalTime') + dataArr[4] + this.$t('robot.second') + ', ' +
                        this.$t('robot.transactionMax') + dataArr[5] + yenUnit1
                } else if (item.type == 'buy_sell_dex') {
                    // Dex高抛低吸机器人
                    return this.$t('robot.higherthan') + dataArr[0] + this.$t('robot.sellout') + ', ' +
                        this.$t('robot.below') + dataArr[1] + this.$t('robot.purchase') + ', ' +
                        this.$t('robot.interval') + dataArr[2] + this.$t('robot.second') + ', ' +
                        this.$t('robot.per') + dataArr[3] + item.currency.split('_')[0] + ', ' +
                        this.$t('robot.maximum') + dataArr[4] + item.currency.split('_')[0] + ', ' +
                        this.$t('robot.minimum') + dataArr[5] + item.currency.split('_')[0] +
                        (this.solWebList.indexOf(item.web) == -1 ? '' : ', ' + this.$t('robot.gasMultiplier') + dataArr[6] + this.$t('robot.times'))
                } else if (item.type == 'frontrun_dex') {
                    // Dex抢买机器人
                    var yenUnit2 = item.currency.split('_')[1]
                    // return this.$t('robot.trigger') + dataArr[0] + yenUnit2 + ', ' + this.$t('robot.everyPurchase') + dataArr[1] + yenUnit2 + (dataArr[2] == 1 ? ', ' + this.$t('robot.isAutoSell') : '')
                    return this.$t('robot.openingTime') + timeFormat(Number(new Decimal(dataArr[0]).mul(new Decimal(1000))), 'dateTime') + ', ' +
                        this.$t('robot.slippage') + dataArr[1] + yenUnit2 + ', ' +
                        this.$t('robot.gas') + dataArr[2] + (this.solWebList.indexOf(item.web) == -1 ? 'GWEI' : this.$t('robot.times')) + ', ' +
                        this.$t('robot.transactionAmount') + dataArr[2] + yenUnit2
                } else if (item.type == 'snipe_dex') {
                    // Dex狙击新盘
                    // return this.$t('robot.purchase')+dataArr[0]+item.currency.split('_')[1]+(dataArr[1]? ', '+this.$t('robot.isAutoSell'):'')
                    return this.$t('robot.contract') + dataArr[0] + ', ' +
                        this.$t('robot.orderAmount') + dataArr[1] + item.currency.split('_')[1] + ', ' +
                        this.$t('robot.multiple') + dataArr[2] + this.$t('robot.times') + ', ' +
                        this.$t('robot.openingTime') + dataArr[3] + ', ' +
                        (dataArr[4] == 1 ? this.$t('robot.taxDeduction') : this.$t('robot.noTaxDeduction'))
                } else if (item.type == 'banzhuan_dex') {
                    // DEX到CEX搬砖
                    return this.$t('robot.targetExchange') + '(CEX)' + dataArr[0] + ', ' + this.$t('robot.currentCharge') + dataArr[1] + '%, ' + this.$t('robot.targetCharge') + dataArr[2] + '%, ' + this.$t('robot.priceDecimal') + dataArr[3] + ', ' + this.$t('robot.quantityDecimal') + dataArr[4]
                } else if (item.type == 'iceberg_dex') {
                    // Dex分批卖出机器人
                    var yenUnit1 = item.currency.split('_')[0]
                    var yenUnit2 = item.currency.split('_')[1]
                    return this.$t('robot.floorPrice') + dataArr[0] + yenUnit2 + ', ' +
                        this.$t('robot.interval2') + dataArr[1] + this.$t('robot.second') + ', ' +
                        this.$t('robot.quantityPerTime') + dataArr[2] + ', ' +
                        this.$t('robot.totalShipments') + dataArr[3] + yenUnit1
                }
                 else if (item.type == 'pump_sniper') {
                    // pump抢买机器人
                    var yenUnit1 = item.currency.split('_')[0]
                    return this.$t('robot.totalPurchaseAmount') + dataArr[1] + yenUnit1 + ', ' +
                        this.$t('robot.monitoredAddress') + dataArr[2]
                }
                else if (item.type == 'shua_nft') {
                    // NFT画线机器人
                    return this.$t('robot.priceRange') + dataArr[0] + '~' + dataArr[1] + ', ' + this.$t('robot.transaction') + dataArr[2] + '~' + dataArr[3] + ', ' + this.$t('robot.intervalTime') + dataArr[4] + this.$t('robot.second') + ', ' + this.$t('robot.direction') + (dataArr[5] == 0 ? this.$t('robot.oscillation') : dataArr[5] == 1 ? this.$t('robot.rise') : dataArr[5] == 2 ? this.$t('robot.fall') : dataArr[5])
                } else if (item.type == 'buy_sell_nft') {
                    // NFT高抛低吸机器人
                    return this.$t('robot.higherthan') + dataArr[0] + this.$t('robot.sellout') + ', ' + this.$t('robot.below') + dataArr[1] + this.$t('robot.purchase') + ', ' + this.$t('robot.interval') + dataArr[2] + this.$t('robot.second') + ', ' + this.$t('robot.per') + dataArr[3] + item.currency.split('_')[0] + ', ' + this.$t('robot.maximum') + dataArr[4] + item.currency.split('_')[0] + ', ' + this.$t('robot.minimum') + dataArr[5] + item.currency.split('_')[0]
                }
            },
        },
    };
</script>